<template>

  <h2>Base inputs</h2>

  <div class="grid">
    <div class="tmp-col">

      <fieldset>
        <legend>This is a fieldset legend</legend>

        <h3>Text input</h3>

        <Input
          id="id_1"
          label="Label"
          @update:model-value="onInputChange"
        />

      </fieldset>

      <h3>Placeholder as label</h3>

      <Input
        id="id_2"
        placeholder="This is a bad practice!"
      />

      <h3>Required</h3>

      <Input
        id="id_3"
        label="Label"
        required
      />

      <h3>Disabled</h3>

      <Input
        id="id_4"
        label="Label"
        disabled
      />

      <h3>Info and error texts</h3>

      <Input
        id="id_5"
        label="Label"
        text-info="This is the text info"
      />

      <Input
        id="id_6"
        label="Label"
        text-info="This is the info text"
        text-error="This field is required."
        error
      />

      <h3>Before and after elements</h3>

      <Input
        id="id_7"
        label="Label"
        input-before="Before"
      />

      <Input
        id="id_8"
        label="Label"
        input-after="mL/s"
      />

      <Input
        id="id_9"
        label="Label"
        input-before="Price"
        input-after="€"
      />

      <Input
        id="id_71"
        label="Label"
        input-before-icon="users"
      />

      <Input
        id="id_81"
        label="Label"
        input-after-icon="arrow-right"
      />

      <Input
        id="id_91"
        label="Label"
        input-before-icon="science"
        input-after-icon="tag"
      />

      <h3>With value</h3>

      <Input
        :value="form.value1"
        id="id_10"
        label="Name"
        text-info="Your full name"
      />

    </div>
    <div class="tmp-col">

      <h3>Other simple input types</h3>

      <Input
        v-model="form.valueNumber"
        type="number"
        id="id_11"
        label="Number"
      />

      <Input
        v-model="form.valueNumberDecimal"
        type="number"
        id="id_1199"
        label="Number (with min, max and step)"
        :options="{
          'step': 0.1,
          'min': -1,
          'max': 1,
        }"
      />

      <Input
        v-model="form.valuePassword"
        type="password"
        id="id_12"
        label="Password"
      />

      <Input
        v-model="form.valueEmail"
        type="email"
        id="id_13"
        label="Email"
      />

      <Input
        v-model="form.valueTel"
        type="tel"
        id="id_14"
        label="Telephone"
      />

      <Input
        v-model="form.valueSearch"
        type="search"
        id="id_15"
        label="Search"
      />

      <Input
        v-model="form.valueUrl"
        type="url"
        id="id_16"
        label="URL"
      />

      <h3>Other input types</h3>

      <Input
        v-model="form.valueDate"
        type="date"
        id="id_17"
        label="Date"
      />

      <Input
        v-model="form.valueTime"
        type="time"
        id="id_18"
        label="Time"
      />

      <Input
        v-model="form.valueColor"
        type="color"
        id="id_19"
        label="Color"
      />

      <Input
        v-model="form.valueRange"
        type="range"
        id="id_20"
        label="Range"
      />

      <InputFile
        v-model="form.valueInputFile"
        textInfo="Texte d'information optionnelle"
        label="File preview"
      />

    </div>
    <div class="tmp-col">

      <h3>Special input types</h3>

      <Input
        v-model="form.valueTextarea"
        type="textarea"
        id="id_22"
        label="Textarea"
      />

      <Checkbox
        v-model="form.valueCheckbox"
        id="id_231"
        :items="[
          { value: 1 },
        ]"
        label="Checkbox (single)"
      />

      <Checkbox
        v-model="form.valueCheckboxMultiple"
        id="id_23"
        :items="[
          { label: 'Label 1', value: 1 },
          { label: 'Label 2 very very very very very very very long', value: 2 },
          { label: 'Label 3', value: 3 },
        ]"
        label="Checkbox (multiple)"
      />

      <Checkbox
        v-model="form.valueCheckboxInline"
        id="id_232"
        :items="[
          { label: 'Label 1', value: 1 },
          { label: 'Label 2', value: 2 },
          { label: 'Label 3', value: 3 },
        ]"
        label="Checkbox (inline)"
        inputStyle="inline"
      />

      <Radio
        v-model="form.valueRadio"
        id="id_24"
        :items="[
          { label: 'Label 1', value: 'value-1'},
          { label: 'Label 2', value: 'value-2' },
          { label: 'Label 3', value: 'value-3' },
        ]"
        label="Radio"
      />

      <Radio
        v-model="form.valueRadioInline"
        id="id_241"
        :items="[
          { label: 'Label 1', value: 'value-1' },
          { label: 'Label 2', value: 'value-2' },
          { label: 'Label 3', value: 'value-3' },
        ]"
        label="Radio (inline)"
        inputStyle="inline"
      />

      <InputImage
        v-model="form.valueInputAvatar"
        avatarInitials="HD"
        textInfo="Texte d'information optionnelle"
        label="Avatar file preview"
      />

      <InputImage
        v-model="form.valueInputImage"
        textInfo="Texte d'information optionnelle"
        label="Image file preview"
        type="picto"
      /> <!-- Possible values: Logo, picto, avatar (default) -->

    </div>
    <div class="tmp-col">

      <h3>SelectExtended</h3>

      <SelectExtended
        id="id_258"
        v-model="form.valueSingleSelect"
        label="Single select"
        option-key="value"
        option-value="label"
        :items="[
          { label: 'Option very very very very very very very very long', value: 'One' },
          { label: 'Option 2', value: 'Two' },
          { label: 'Option 3', value: 'Three' },
        ]"
      />

      <SelectExtended
        id="id_261"
        label="Single select with search"
        search
        option-key="value"
        option-value="label"
        :items="[
          { label: 'Option 1', value: 'One' },
          { label: 'Option 2', value: 'Two' },
          { label: 'Option 3', value: 'Three' },
        ]"
      />

      <SelectExtended
        id="id_259"
        label="Select multiple without chips"
        multiple
        option-key="value"
        option-value="label"
        :items="[
          { label: 'Option 1', value: 'One' },
          { label: 'Option 2', value: 'Two' },
          { label: 'Option 3', value: 'Three' },
        ]"
        text-info="This is the text info"
      />

      <SelectExtended
        v-model="form.valueSelectExtended"
        id="id_260"
        label="Select multiple with chips"
        multiple
        chips
        option-key="value"
        option-value="label"
        :items="[
          { label: 'Option 1', value: 'One' },
          { label: 'Option 2', value: 'Two' },
          { label: 'Option 3', value: 'Three' },
        ]"
        text-info="Plusieurs choix possibles"
      />

      <SelectExtended
        v-model="form.valueSelectExtendedSelected"
        id="id_260"
        label="Select multiple with chips with default value"
        multiple
        chips
        option-key="value"
        option-value="label"
        :items="[
          { label: 'Option 1', value: 1 },
          { label: 'Option 2', value: 2 },
          { label: 'Option 3', value: 3 },
        ]"
        text-info="Plusieurs choix possibles"
      />

      <SelectExtended
        v-model="form.valueSelectExtendedGroup"
        id="id_265"
        label="Select with group"
        group-label="nom"
        group-values="enfants"
        :group-select="false"
        option-key="id"
        option-value="nom"
        apiEndpoint="dictionnaire/categorie/fichier"
      />

      <SelectExtended
        v-model="form.valueSelectMultipleExtendedGroup"
        id="id_265"
        label="Select multiple with group"
        group-label="nom"
        group-values="enfants"
        :group-select="true"
        multiple
        option-key="id"
        option-value="nom"
        apiEndpoint="dictionnaire/categorie/fichier"
      />

      <SelectExtended
        id="id_2588"
        v-model="form.valueSingleSelect"
        label="Big single select"
        option-key="value"
        option-value="label"
        :items="[
          { label: 'Option very very very very very very very very long', value: 'One' },
          { label:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi ' +
            'laborum expedita pariatur maxime nobis, asperiores vel quisquam ' +
            'modi autem quasi esse voluptas. At, harum corporis!', value: 'Two' },
          { label: 'Option 3', value: 'Three' },
        ]"
        big
      />

      <SelectExtended
        id="id_2599"
        label="Big select multiple"
        multiple
        option-key="value"
        option-value="label"
        :items="[
          { label: 'Option very very very very very very very very long', value: 'One' },
          { label:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi ' +
            'laborum expedita pariatur maxime nobis, asperiores vel quisquam ' +
            'modi autem quasi esse voluptas. At, harum corporis!', value: 'Two' },
          { label: 'Option 3', value: 'Three' },
        ]"
        big
        text-info="Do NOT use multiple and big together!"
      />

      <SelectExtended
        id="id_268"
        label="Error single select"
        key="value"
        value="label"
        :items="[
          { label: 'Option very very very very very very very very long', value: 'One' },
          { label: 'Option 2', value: 'Two' },
          { label: 'Option 3', value: 'Three' },
        ]"
        text-error="Something went wrong!"
        error
      />

      <SelectExtended
        id="id_269"
        label="Disabled single select"
        key="value"
        value="label"
        :items="[
          { label: 'Option very very very very very very very very long', value: 'One' },
          { label: 'Option 2', value: 'Two' },
          { label: 'Option 3', value: 'Three' },
        ]"
        disabled
      />

    </div>

    <div class="tmp-col">

      <h3>FORM DATA</h3>

      <pre><code>{{ JSON.stringify(form, null, 2) }}</code></pre>

    </div>
    <div class="tmp-col">

      <h3>Notation inputs (stronger focus style)</h3>

      <Input
        id="id_2a"
        placeholder="This is a bad practice!"
        strong-focus
      />

      <Input
        id="id_2b"
        type="number"
        text-info="Input type number"
        strong-focus
      />

      <Input
        id="id_2c"
        label="Label"
        input-after="mL/s"
        strong-focus
      />

      <Input
        :value="form.value1"
        id="id_2d"
        label="Name"
        strong-focus
      />

      <Input
        v-model="form.valueTextarea"
        type="textarea"
        id="id_2e"
        label="Textarea"
        strong-focus
      />

      <Input
        id="id_2z"
        disabled
        strong-focus
      />

    </div>
  </div><!-- End of .grid -->

  <h2>Forms layout</h2>

  <h3>Block form - block inputs</h3>

  <div class="form grid">
    <div class="tmp-col">
      <Input
        id="id_100"
        label="Label"
      />
    </div>
    <div class="tmp-col">
      <Input
        id="id_101"
        label="Label"
      />
    </div>
    <div class="tmp-col">
      <Input
        id="id_102"
        label="Label"
      />
    </div>
    <div class="tmp-col">
      <Input
        id="id_103"
        label="Label"
      />
    </div>
  </div>

  <h3>Block form - inline inputs</h3>

  <div class="form grid">
    <div class="tmp-col">
      <Input
        id="id_200"
        label="Label"
        inline
      />
    </div>
    <div class="tmp-col">
      <Input
        id="id_201"
        label="Label"
        inline
      />
    </div>
    <div class="tmp-col">
      <SelectExtended
        id="id_278"
        label="Single select"
        key="value"
        value="label"
        :items="[
          { label: 'Option very very very very very very very very long', value: 'One' },
          { label: 'Option 2', value: 'Two' },
          { label: 'Option 3', value: 'Three' },
        ]"
        inline
      />
    </div>
    <div class="tmp-col">
      <Radio
        id="id_2411"
        :items="[
          { label: 'Label 1', value: 'value-1' },
          { label: 'Label 2', value: 'value-2' },
        ]"
        label="Radio (inline)"
        inputStyle="inline"
        inline
      />
    </div>
    <div class="tmp-col">
      <Checkbox
        id="id_2311"
        :items="[
          { value: 1, checked: true },
        ]"
        label="Checkbox"
        inline
      />
    </div>
  </div>

  <hr>

  <h2>Key Value</h2>

  <div class="grid">
    <div class="tmp-col">

      <h3>Simple text value</h3>

      <KeyValue label="Example key" value="Example value"/>

      <h3>Textarea value</h3>

      <KeyValue label="My key"
                value="Lorem ipsum dolor sit amet, consectetur adipisicing elit.
Eum harum commodi hic recusandae iure, labore nisi quaerat
saepe totam ab doloribus, corrupti, illum quibusdam modi!
Illo tempora quo incidunt provident."
      />

      <h3>Checkbox value (single)</h3>
      <KeyValue label="Checkbox label" checked type="checkbox"/>

      <h3>Checkbox value (multiple)</h3>
      <KeyValue label="Checkbox label" :value="['foo', 'bar', 'baz']" type="checkbox"/>

      <h3>Radio value</h3>
      <KeyValue label="My key" value="My value" type="radio"/>

      <h3>Select value (single)</h3>
      <KeyValue label="My key" value="My value" type="select"/>

      <h3>Select value (multiple)</h3>
      <KeyValue label="My key" :value="['foo', 'bar', 'baz']" type="select"/>
    </div>
    <div class="tmp-col">
      <h3>Date and time values</h3>
      <KeyValue label="My key" value="2021-06-21" type="date"/>
      <KeyValue label="My key" value="13:37" type="time"/>

      <h3>File value</h3>
      <KeyValue label="My key" value="my-pic.jpg" type="file"/>

      <h3>Default value</h3>
      <KeyValue label="Example key" />

      <h3>Default value override</h3>
      <KeyValue label="Example key" default="Non-disponible" />

      <h3>Slot value</h3>
      <KeyValue label="Example key">
        <Tag text="This is a tag"/>
      </KeyValue>

      <h3>Hidden value</h3>
      <KeyValue label="Example key" value="Example value" hidden/>
    </div>
  </div>

  <hr>

  <h2>Key Value - Table variant</h2>

  <KeyValue table label="Example key" value="Example value"/>
  <KeyValue table label="My key"
            value="Lorem ipsum dolor sit amet, consectetur adipisicing elit.
Eum harum commodi hic recusandae iure, labore nisi quaerat
saepe totam ab doloribus, corrupti, illum quibusdam modi!
Illo tempora quo incidunt provident."
  />
  <KeyValue table label="Checkbox label" checked type="checkbox"/>
  <KeyValue table label="Checkbox label" :value="['foo', 'bar', 'baz']" type="checkbox"/>
  <KeyValue table label="My key" value="My value" type="radio"/>
  <KeyValue table label="My key" value="My value" type="select"/>
  <KeyValue table label="My key" :value="['foo', 'bar', 'baz']" type="select"/>
  <KeyValue table label="My key" value="2021-06-21" type="date"/>
  <KeyValue table label="My key" value="13:37" type="time"/>
  <KeyValue table label="My key" value="my-pic.jpg" type="file"/>
  <KeyValue table label="Example key" />
  <KeyValue table label="Example key" default="Non-disponible" />
  <KeyValue table label="Example key">
    <Tag text="This is a tag"/>
  </KeyValue>
  <KeyValue table label="Example key" value="Example value" hidden/>
  <KeyValue table highlight label="Highlight key" value="Example value"/>
  <KeyValue table label="Parent key" value="Example value"/>
  <KeyValue table child label="Child key" value="Example value"/>
  <KeyValue table highlight multiple label="Example key">
    <div class="key-value-value-item">Foo</div>
    <div class="key-value-value-item">Bar</div>
    <div class="key-value-value-item">Baz</div>
  </KeyValue>
  <KeyValue table multiple label="Example key">
    <div class="key-value-value-item">Foo</div>
    <div class="key-value-value-item">Bar</div>
    <div class="key-value-value-item">Baz</div>
  </KeyValue>
  <KeyValue table multiple labelInput label="Example key">
    <template v-slot:inputLabel>
      <Input
        id="id_3333"
        placeholder="This is a bad practice!"
      />
    </template>
    <Input
      id="id_3334"
      placeholder="This is a bad practice!"
    />
    <Input
      id="id_3335"
      placeholder="This is a bad practice!"
    />
    <Input
      id="id_3336"
      placeholder="This is a bad practice!"
    />
  </KeyValue>
  <KeyValue table label="Input label" type="input">
    <Input
      id="id_2222"
      placeholder="This is a bad practice!"
    />
  </KeyValue>
  <KeyValue table label="Input label" type="input" required>
    <Radio
      id="id_2411"
      :items="[
        { label: 'Label 1', value: 'value-1' },
        { label: 'Label 2', value: 'value-2' },
      ]"
      inputStyle="inline"
    />
  </KeyValue>
  <KeyValue table label="Input label" type="input" required>
    <Input
      id="id_6666"
      text-info="This is the info text"
      text-error="This field is required."
      error
    />
  </KeyValue>
  <KeyValue table label="Input label" type="input" required>
    <SelectExtended
      v-model="form.valueSelectExtended"
      id="id_260"
      multiple
      chips
      option-key="value"
      option-value="label"
      :items="[
        { label: 'Option 1', value: 'One' },
        { label: 'Option 2', value: 'Two' },
        { label: 'Option 3', value: 'Three' },
      ]"
      text-info="Plusieurs choix possibles"
    />
  </KeyValue>
  <KeyValue table label="Input label" type="input">
    <Checkbox
      v-model="form.valueCheckboxInline"
      id="id_232"
      :items="[
        { label: 'Label 1', value: 1 },
        { label: 'Label 2', value: 2 },
        { label: 'Label 3', value: 3 },
      ]"
      inputStyle="inline"
    />
  </KeyValue>
  <KeyValue table label="Input label" type="input">
  </KeyValue>

</template>

<script>
import Input from '@/components/form/Input.vue'
import Radio from '@/components/form/Radio.vue'
import Checkbox from '@/components/form/Checkbox.vue'
import KeyValue from '@/components/form/KeyValue.vue'
import InputImage from '@/components/form/InputImage.vue'
import InputFile from '@/components/form/InputFile.vue'
import SelectExtended from '@/components/form/SelectExtended.vue'
import Tag from '@/components/base/Tag.vue'

export default {
  name: 'StyleguideFormsView',

  components: {
    Input,
    Radio,
    Checkbox,
    KeyValue,
    InputImage,
    InputFile,
    SelectExtended,
    Tag,
  },

  data() {
    // pour tester le repeatbleInputs

    const validationSchema = this.yup.object().shape({
      substance: this.yup.array().required(),
      quantite: this.yup.array().required(),
      unite: this.yup.array().required(),
    })

    const { errors, isSubmitting } = this.formService.initFrom(
      validationSchema,
    )
    const onSubmit = this.formService.handleSubmit((values) => {
      this.handleValues(values)
    })

    // Fin du test

    return {
      errors,
      isSubmitting,
      onSubmit,
      form: {
        value1: 'John Doe',
        valueNumber: 42,
        valueNumberDecimal: 0.3,
        valuePassword: 'adminpassword',
        valueEmail: 'john.doe@gmail.com',
        valueTel: '+03315482659',
        valueSearch: 'Is the earth flat?',
        valueUrl: 'v3.vuejs.org/guide',
        valueDate: '2021-06-21',
        valueTime: '13:37',
        valueColor: '#FF8000',
        valueRange: '21',
        valueFile: '',
        valueTextarea: 'Lorem ipsum dolor sit amet',
        valueCheckbox: [2],
        valueCheckboxMultiple: [1, 3],
        valueCheckboxInline: [3],
        valueRadio: 'value-1',
        valueRadioInline: 'value-2',
        valueSelect: '',
        valueInputAvatar: {},
        valueInputLogo: {},
        valueInputPhoto: {},
        valueInputFile: {},
        valueSelectExtended: [],
        valueSelectExtendedSelected: [
          { key: 1, value: 'Option 1' },
          { key: 2, value: 'Option 2' },
        ],
        valueSelectExtendedGroup: [],
        valueSelectMultipleExtendedGroup: [],
        valueSingleSelect: { key: 2, value: 'Two' },
        repeatableInput: {},
      },
      value: [],
    }
  },

  methods: {
    onInputChange(event) {
      console.log('Input change', event)
    },

    handleValues(values) {
      console.log(values)
      const substances = values.substance.map(
        (substance) => substance.map((subst) => ({ uid: subst.value })),
      )
      console.table(substances)
    },
  },
}
</script>
