<template>
  <div
    class="input-block input-image"
    :class="{
      'input-block--required': required,
      'input-block--disabled': disabled,
      'input-block--error': errorMessage,
      'input-block--inline': inline,
    }"
  >
    <label :for="id" v-if="label !== undefined && label !== ''">{{
        label
      }}</label>
    <div class="input" v-if="displayFileName">
      <div class="input-image-filename">
        <span>{{ file ? file.nom : 'Aucun fichier choisi' }}</span>
        <Btn
          class="input-image-btn-remove"
          v-if="fileUploaded"
          :key="componentKey"
          @click="deleteFile"
          color="default"
          icon="times-circle"
          size="xs"
          grow
          round
        />
      </div>
    </div>
    <div class="input-image-label">
      <Btn
        text="Choisir un fichier"
        color="default"
        icon="plus"
        @click="modal.addFile = true"
      />
    </div>
  </div>

  <Modal
    title="Ajouter un fichier"
    :active="modal.addFile"
    @modal-close="modal.addFile = false"
  >
    <template v-slot:modal-body>
      <div
        class="drop"
        :class="getClasses"
        @dragover.prevent="dragOver"
        @dragleave.prevent="dragLeave"
        @drop.prevent="select($event)"
      >
        <h1 style="margin-bottom: 0">Glisser-déposer votre fichier ici</h1>
      </div>

      <div class="input">
        <label class="input-image-label">
          <input
            class="input-image-input"
            v-if="!file || (file && $route.params.id) || media"
            :id="id"
            type="file"
            ref="inputFile"
            :name="name ? name : id"
            :disabled="disabled"
            @change="select"
          />
          <Btn
            :style="{ opacity: isDragging ? 0 : 1 }"
            class="input-image-btn-add"
            :text="loading ? 'En cours de téléversement...' : 'Choisir un fichier'"
            color="default"
            icon="plus"
            :disabled="loading"
          />
        </label>

        <div class="input-text input-text--info" v-if="textInfo && !errorMessage">
          {{ textInfo }}
        </div>
        <div class="input-text input-text--error" v-if="errorMessage">
          {{ errorMessage }}
        </div>
      </div>
    </template>
  </Modal>

</template>

<script>
import axios from 'axios'
import Btn from '@/components/base/Btn.vue'
import { useField } from 'vee-validate'
import Modal from '@/components/layout/Modal.vue'

export default {
  name: 'InputFile',

  components: {
    Modal,
    Btn,
  },

  props: {
    displayFileName: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      default: 'input-image-upload',
    },
    media: {
      type: Object,
    },
    // Button
    buttonText: {
      type: String,
      default: 'Supprimer le fichier',
    },
    // Input
    name: {
      type: String,
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
      default: ' ',
    },
    required: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    textInfo: {
      type: String,
    },
    textError: {
      type: String,
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },

  data(props) {
    const {
      errorMessage,
      handleBlur,
      handleChange,
    } = useField(props.id)

    return {
      errorMessage,
      handleBlur,
      handleChange,
      chunks: [],
      chunkSize: 0,
      numberOfChunks: 0,

      // Data updated to perform a component reload
      index: 1,
      componentKey: 0,

      // Object
      file: null,
      // Keep the image is if the initial in the props
      initialFile: true,
      // Data of the uploaded image
      fileData: {},
      // Determine if the image is already uploaded
      fileUploaded: false,
      loading: false,

      modal: {
        addFile: false,
      },

      isDragging: false,
    }
  },

  computed: {
    formData() {
      const chunks = Math.ceil((this.file.size / 1024) / 1024)

      const data = {
        morceau: this.chunks[0],
        position: this.index,
        meta: {
          nom: this.file.nom,
          taille: Math.round(this.file.size / 1024),
          extension: this.file.name.split('.').pop().toLowerCase(),
          longueur: chunks,
          type_uid: 'fichier',
        },
      }

      return this.fileService.setData(data)
    },
    config() {
      return {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${this.$store.state.auth.user.token}`,
        },
        data: this.formData,
      }
    },
    getClasses() {
      return {
        isDragging: this.isDragging,
      }
    },
  },

  watch: {
    // eslint-disable-next-line func-names
    media() {
      if (!this.fileUploaded) {
        this.file = this.media ?? null
        this.fileUploaded = true

        this.updateInput(this.file)
      }
    },
  },

  mounted() {
    this.file = this.media || null

    if (this.file) {
      this.fileUploaded = true
    }
  },
  methods: {
    dragOver() {
      this.isDragging = true
    },
    dragLeave() {
      this.isDragging = false
    },

    async select(event) {
      this.isDragging = false
      if (this.fileUploaded) {
        this.deleteFile(false)
      }

      this.file = 'dataTransfer' in event ? event.dataTransfer.files[0] : event.target.files.item(0)
      this.file.nom = this.file.name
      delete this.file.name

      const alert = (text) => this.emitter.emit('alert', {
        type: 'warning',
        content: text,
      })

      const checkUploadedFile = (fileSizeInMegabytes, validExtensions) => {
        if (this.file.size > this.fileService.formatMegaBytes(fileSizeInMegabytes)) {
          alert(`La taille du fichier ne peut pas être supérieure à ${fileSizeInMegabytes}Mo`)
          this.resetForm()
        } else if (!this.fileService.hasExtensions(this.file, validExtensions)) {
          alert(`L'extension est invalide, le type de fichier attendu est : ${validExtensions.join(', ')}`)
          this.resetForm()
        } else {
          this.uploadFile()
        }
        this.resetForm()
      }

      checkUploadedFile(1, ['pdf'])
    },

    uploadFile() {
      this.chunkSize = Math.min(this.file.size / 1024, 1024)
      this.numberOfChunks = Math.ceil((this.file.size / 1024) / this.chunkSize)

      this.loading = true
      for (let i = 0; i < this.numberOfChunks; i += 1) {
        this.chunks.push(this.file.slice(
          i * (this.chunkSize * 1024),
          (i + 1) * (this.chunkSize * 1024),
        ))

        // Upload avatar
        axios(`${process.env.VUE_APP_ROOT_API}utilitaire/media/televerser`, this.config)
          .then((response) => {
            this.chunks.shift()
            this.index += 1

            this.file = response.data.data

            if (response.status === 201) {
              this.updateInput(response.data.data)

              this.initialFile = false
              this.fileUploaded = true
              this.loading = false
              this.modal.addFile = false
              this.isDragging = false
            }
          }).catch((error) => console.log(error))
      }
    },

    updateInput(event) {
      this.handleChange(event)
      this.$emit('update:modelValue', event)
    },

    deleteFile(displayAlert = true) {
      if (this.initialFile) {
        this.updateInput(null)
        this.file = null
        this.resetForm()
      } else {
        this.fetchService.delete(`utilitaire/media/${this.file.id}`).then(() => {
          if (displayAlert) {
            this.emitter.emit('alert', {
              type: 'success',
              content: 'Le fichier a bien été supprimé',
            })
          }

          this.updateInput(null)
          this.resetForm()
        })
      }
    },

    resetForm() {
      this.chunks = []
      this.index = 1
      this.file = null
      this.chunkSize = 0
      this.numberOfChunks = 0
      this.fileUploaded = false
      this.componentKey += 1
    },
  },
}
</script>

<style lang="scss" scoped>
/* INPUT IMAGE */

.input-image {}

.input {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input-image-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.input-image-label {
  display: flex;
  justify-content: center;
  margin-bottom: 0.8rem;
  z-index: 1
}

.input-text.input-text--info {
  z-index: 1
}

.user-avatar {
  margin-bottom: $gutter-half;
}

.input-image-filename {
  margin-bottom: $gutter-half;

  > span {
    font-family: monospace;
    word-break: break-all;
  }
}

.input-image-btn-add {
  pointer-events: none;
}

.input-image-btn-remove {}

$image-size: 8rem;

.image {
  display: flex;
  justify-content: center;
  align-items: center;
  @include size($image-size);
  background-position: 50% 50%;
  background-size: cover;
}

.drop{
  width: 100%;
  height: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color .2s ease-in-out;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin-bottom: 2rem;
  background: white;
  z-index: 1
}

.isDragging{
  z-index: 1;
  background-color: $color-primary;
  border-width: 2px;
  border-color: #fff;
  border-style: dashed;
  opacity: 1;

  + .input > .input-image-label {
    z-index: -1
  }

  + .input .input-text.input-text--info {
    z-index: -1
  }
}
</style>
